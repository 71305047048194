import React from 'react'
import SliderBanner from './SliderBanner'
import SunglassSection from './SunglassSection'


const trendIconStyle = {
  width: "100px",
  height: "100px",
  marginBottom: "10px",
  horizontalAlign:"middle"
 }

 const trendImages = [
  {
    url: '/home/wayfarer.webp',
  },
  {
    url: '/home/avaitor.webp',
  },
  {
    url: '/home/oval.webp',
  },
  {
    url: '/home/hexa.webp',
  },
  {
    url: '/home/transparent.webp',
  },
  {
    url: '/home/cateye.jpg',
  },
]; 

export default function Home() {
  return (
    <>
    <div className="my-20" style={{marginTop: '56px'}}>
        <SliderBanner/>
    </div>
    <div className="container my-3">
      <center>
        <h1>WEAR THE TREND</h1>
      </center>
    </div>

    
    <div className="container-fluid">
    <div className="card">  
      <div id="my_row_edits" className="row mb-3">
        <div id="trend1" className="col">
            <center>
              <img className="img-responsive" src={trendImages[0].url} style={trendIconStyle} alt="loading.." />
            </center>
            <center><label className="my_profile_label_edit" htmlFor="">Wayfarer</label></center>
        </div>
        <div id="trend1" className="col">
            <center>
              <img className="img-responsive" src={trendImages[1].url} style={trendIconStyle} alt="loading.." />
            </center>
            <center><label className="my_profile_label_edit" htmlFor="">Aviator</label></center>
        </div>
        <div id="trend1" className="col">
            <center>
              <img className="img-responsive" src={trendImages[2].url} style={trendIconStyle} alt="loading.." />
            </center>
            <center><label className="my_profile_label_edit" htmlFor="">Oval</label></center>
        </div>
        <div id="trend1" className="col">
            <center>
              <img className="img-responsive" src={trendImages[3].url} style={trendIconStyle} alt="loading.." />
            </center>
            <center><label className="my_profile_label_edit" htmlFor="">Hexa</label></center>
        </div>
        <div id="trend1" className="col">
            <center>
              <img className="img-responsive" src={trendImages[4].url} style={trendIconStyle} alt="loading.." />
            </center>
            <center><label className="my_profile_label_edit" htmlFor="">Transparent</label></center>
        </div>
        <div id="trend1" className="col">
            <center>
              <img className="img-responsive" src={trendImages[5].url} style={trendIconStyle} alt="loading.." />
            </center>
            <center><label className="my_profile_label_edit" htmlFor="">Cateye</label></center>
        </div>
        
      </div>
      </div>
    </div>
    <SunglassSection/>
    <div className="my-3 mb-3">
      <img src='/home/blp.png' style={{ width: "100%"}} alt = "loading..." />
    </div>

    </>
    
  )
}
