import React from 'react'
import SunglassSlider from './SunglassSlider'

export default function SunglassSection() {
  return (
    <div className='my-3'>
      <center>
        <h1>SUNGLASSES</h1>
      </center>

      <SunglassSlider/>

    </div>
  )
}
