import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';


let facebookIcon = "/facebook.png";
let instagramIcon = "/instagram.png";
let appLogo = "/applogo.png";

let instagramPageLink = "https://www.instagram.com/invites/contact/?i=10ut48nf45cae&utm_content=tiwz7ny",
    facebookPageLink = "https://www.instagram.com/invites/contact/?i=10ut48nf45cae&utm_content=tiwz7ny";


export default function Navbar(props) {
  return (
    <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
            <a className="icon-link icon-link-hover" target="_blank" rel="noreferrer" href="/"><img src={appLogo} width={100} alt="loading.." /></a>
        
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                    <Link className="nav-link active" aria-current="page" to="/">Home</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/about">{props.aboutText}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/contact-us">Contact Us</Link>
                </li>
                
                </ul>
                <form className="d-flex" role="search">
                <div
                style={{
                    margin: '0px 10px 0px 0px'
                }}
                >
                <a className="icon-link icon-link-hover" target="_blank" rel="noreferrer" href={facebookPageLink}><img src={facebookIcon} width={25} height={25} alt="facebook" /></a>
                </div>
                
                <div
                style={{
                    margin: '0px 10px 0px 0px'
                }}
                >
                <a className="icon-link icon-link-hover" target="_blank" rel="noreferrer" href={instagramPageLink}><img src={instagramIcon} width={25} height={25} alt="instagram" /></a>
                </div>
                
                
                
                </form>
            </div>
        </div>
    </nav>
  )
}

Navbar.propTypes = {
    title: PropTypes.string.isRequired,
    aboutText: PropTypes.string.isRequired
}

Navbar.defaultPop = {
    title: "Set Title",
    aboutText: "About"
}

