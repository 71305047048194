import React from 'react'

import { Zoom  } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

const slideImages = [
    {
      url: '/sungleasses/banner1.jpeg',
    },
    {
      url: '/sungleasses/banner2.jpeg',
    },
    {
      url: '/sungleasses/banner3.jpeg',
    },
    {
      url: '/sungleasses/banner4.jpeg',
    },
  ]; 

const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    height: '400px'
  }

  export default function SunglassSlider() {
  return (
    <div className="slide-container">
       <Zoom scale={0.4}>
         {slideImages.map((slideImage, index)=> (
            <div key={index}>
              <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>

              </div>
            </div>
          ))} 
        </Zoom>
        
      </div>    
    
  )
}