import React from 'react'
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const slideImages = [
    {
      url: '/banners/tommy.png',
    },
    {
      url: '/banners/scavin.png',
    },
    {
      url: '/banners/walnut.png',
    },
    {
      url: '/banners/rayban.png',
    },
    {
      url: '/banners/polarid.png',
    },
    {
      url: '/banners/opium.png',
    },
    {
      url: '/banners/henarycarter.png',
    },
    {
      url: '/banners/carrera.png',
    },
  ];                    

  const settings = {
    dots: false,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
  };

function SliderBanner() {
  return (
    
    <div>

      <Slider {...settings}>
          {
            slideImages.map((item)=>{
                return <div>
                  <img  width ={'100%'} src={item.url} alt="loading.." />
                </div>    
            })
          }
      </Slider>

    </div>
    /*<div className="each-slide-effect">
       <Slide>
         {slideImages.map((slideImage, index)=> (
            <div key={index}>
              <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>

              </div>
            </div>
          ))} 
        </Slide>
        
      </div>    
    */
  )
}

export default SliderBanner
