import React from 'react'
import { Link } from 'react-router-dom';

let emailIconUrl = "/others/mail.png";
let facebookIcon = "/facebook.png";
let instagramIcon = "/instagram.png";
let phoneIconUrl = "/others/telephone.png";

const iconStyle = {
    width: "18px",
    height: "18px",
    marginRight: "5px",
    verticalAlign:"middle"
  
   }

   const footerStyle = {
    backgroundColor: "rgba(214, 213, 227, 0.1)"
   }

let instagramPageLink = "https://www.instagram.com/invites/contact/?i=10ut48nf45cae&utm_content=tiwz7ny",
    facebookPageLink = "https://www.instagram.com/invites/contact/?i=10ut48nf45cae&utm_content=tiwz7ny";    


export default function AppFooter() {
  return (
    <div style={footerStyle}>
         <div>
            <div className="container-fluid">
                <br/>
                <div id="my_row_edits" className="row">
                    <div id="Support" className="col">
                        <h4><label className="my_profile_label_edit" htmlFor="">Support</label></h4>
                        <Link className="nav-link active" aria-current="page" to="/">Terms & Conditions</Link>    
                        <Link className="nav-link" to="/about">Privacy Policy</Link>
                        
                    </div>

                    <div id="knowMore" className="col">
                        <h4><label className="my_profile_label_edit" htmlFor="">Know More</label></h4>
                        <Link className="nav-link active" aria-current="page" to="/">Home</Link>    
                        <Link className="nav-link" to="/about">About</Link>
                        <Link className="nav-link" to="/contact-us">Contact Us</Link>
                        
                    </div>

                    <div id="needHelp" className="col">
                        <h4><label className="my_profile_label_edit" htmlFor="">Need Help?</label></h4>
                        <h6>Call Us on</h6>  
                        <img className="img-responsive" src={phoneIconUrl} style={iconStyle} alt="phone" />+91-9418425825
                        <br/>
                        <img className="img-responsive" src={phoneIconUrl} style={iconStyle} alt="phone" />01972-796481
                        <br/>
                        <hr height={2}/>
                        <h6>Email Us at</h6>  
                        <p><img className="img-responsive" src={emailIconUrl} style={iconStyle} alt="email" />thevisionmarthamirpur@gmail.com</p>
                    </div>

                    <div id="followUs" className="col">
                        <h4><label className="my_profile_label_edit" htmlFor="">Follow Us</label></h4>
                        <br/>
                        <div style={{
                            margin: '0px 10px 0px 0px'
                        }}
                        >
                        <a className="icon-link icon-link-hover" target="_blank" rel="noreferrer" href={facebookPageLink}><img src={facebookIcon} width={25} height={25} alt="facebook" />
                        <h6>Facebook</h6>
                        </a>
                        </div>
                        
                        <div
                        style={{
                            margin: '0px 10px 0px 0px'
                        }}
                        >
                        <a className="icon-link icon-link-hover" target="_blank" rel="noreferrer" href={instagramPageLink}><img src={instagramIcon} width={25} height={25} alt="instagram" />
                        <h6>Instagram</h6>
                        </a>
                        </div>
                    </div>

                </div>
                <div id="copyRight" className="row">

                    <div id="callUs" className="col">
                        <h6><label className="my_profile_label_edit" htmlFor="">Copyright 2024 © Vision Mart.</label></h6>
                    
                    </div>

                </div>
            </div>
          </div>  
            <br/> 
    </div>
    
  )
}
