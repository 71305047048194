import './App.css';

import React from 'react';
import Navbar from './components/Navbar';
import About from './components/About';
import Home from './components/Home';
import ContactUs from './components/ContactUs';

import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import AppFooter from './components/AppFooter';



function App() {
  return (
    <>
    <Router>
      <div>
        <Navbar title="Vision Mart" aboutText="About Us"/>
        <Routes>
        <Route exact path="/about"  element= {<About/>}/>
        <Route exact path="/contact-us"  element= {<ContactUs/>}/>
        <Route exact path="/"  element= {<Home/>}/>
        </Routes>
        <footer>
          <AppFooter/>
        </footer>
      </div>
    </Router>
  </>

  );
}
export default App;
