 import React from 'react'



export default function About() {
  return (
    <div className="container my-20" style={{marginTop: '60px'}}>

      <div className="card">
      <div className="card-body">
                <h1 className="card-title">About Us</h1>
                <p className="card-text">
                "See Clearly, Save Smartly! Vision Mart offers comprehensive eye exam with the latest automated technology, ensuring accuracy and efficiency. Choose from a wide variety of stylish frames from top brands at unbeatable prices. Plus get your lenses fitted the same day in most cases! Schedule your appointment today and experience the Vision Mart difference! Here's What Makes Us Different: * Affordable Eye Exam: We believe everyone deserves quality eye care, and our prices reflect that. * Cutting-Edge Technology: Our advanced equipment provides the most accurate diagnoses. * Wide Selection Of Frames: Find the perfect pair to match your style with option from popular brands. * Get your Glasses fitted the same day in most cases."
                </p>
                
            </div>
            <br/>
          <img src="/about/about_us.jpg" className="card-img-bottom" alt="loading..."/>
            
      </div>
     </div>
  )
}
