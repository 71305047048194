import React from 'react'




 const iconStyle = {
  width: "20px",
  height: "20px",
  marginRight: "10px",
  verticalAlign:"middle"

 }

 let facebookIcon = "/facebook.png";
 let instagramIcon = "/instagram.png";
 let emailIconUrl = "/others/mail.png";
 let phoneIconUrl = "/others/telephone.png";

 let instagramPageLink = "https://www.instagram.com/invites/contact/?i=10ut48nf45cae&utm_content=tiwz7ny",
    facebookPageLink = "https://www.instagram.com/invites/contact/?i=10ut48nf45cae&utm_content=tiwz7ny";


export default function ContactUs() {
  return (
    <div className="container my-20" style={{marginTop: '60px'}}>

      <div className="card">
        <div className="card-body">
          <p>How can we help you?</p>
          <h1 className="card-title">Get In Touch With Us</h1>
          <p>We’re here to help and answer any questions you
             might have. We look forward to hearing from you!
          </p>
          
          <div className="container-fluid">
            <div id="my_row_edits" className="row">
                <div id="callUs" className="col">
                    <h4><label className="my_profile_label_edit" htmlFor="">Call Us</label></h4>
                    <p>Prefer to Call? Reach out to our Sales team directly for immediate assistance.</p>      
                    <p><img className="img-responsive" src={phoneIconUrl} style={iconStyle} alt="phone" />+91-9418425825</p>
                    <p><img className="img-responsive" src={phoneIconUrl} style={iconStyle} alt="phone" />01972-796481</p>
                </div>

                <div id="writeUs" className="col">
                    <h4><label className="my_profile_label_edit" htmlFor="">Write Us</label></h4>
                    <p>Prefer to email? Send us an email and we’ll get back to you soon.</p>      
                    <p><img className="img-responsive" src={emailIconUrl} style={iconStyle} alt="email" />thevisionmarthamirpur@gmail.com</p>
                </div>

                <div id="followUs" className="col">
                    <h4><label className="my_profile_label_edit" htmlFor="">Social Media</label></h4>
                    <p>Prefer to social media? Follow us on Facebook and Instagram</p>      
                    <div
                    style={{
                        margin: '0px 10px 0px 0px'
                    }}
                    >
                    <a className="icon-link icon-link-hover" target="_blank" rel="noreferrer" href={facebookPageLink}><img src={facebookIcon} width={25} height={25} alt="facebook" />
                    <h6>Connect on Facebook</h6>
                    </a>
                    </div>
                    
                    <div
                    style={{
                        margin: '0px 10px 0px 0px'
                    }}
                    >
                    <a className="icon-link icon-link-hover" target="_blank" rel="noreferrer" href={instagramPageLink}><img src={instagramIcon} width={25} height={25} alt="instagram" />
                    <h6>Connect on Instagram</h6>
                    </a>
                    </div>
                </div>

            </div>

            <div id="address" className="row">

            <div id="callUs" className="col">
                    <h4><label className="my_profile_label_edit" htmlFor="">Our Store</label></h4>
                    <p>Monday - Saturday</p>
                    <p>09:00 AM - 08:00 PM</p>
                    <h5>Hamirpur</h5>
                    <p>VisionMart Main Bazar, near Shiv Temple, Kohta, Hamirpur, Himachal Pradesh 177001</p>
                </div>

            </div>
           
        </div>
          
          
        </div>
        <iframe width="100%" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=VisionMart%20Main%20Bazar,%20near%20Shiv%20Temple,%20Kohta,%20Hamirpur,%20Himachal%20Pradesh%20177001+(Vision%20Mart)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps trackers</a></iframe>
      </div>
      
     </div>
  )
}
